.btn {
    font-family: 'Poppins', sans-serif;
    padding: 14px 20px;
    font-size: 2rem;
    border-radius: 5px 5px;
    cursor: pointer;
}

.transparent-btn {
    background-color: rgba(15, 47, 78, 0.7);
    color: var(--white-color);
    border: 1px solid;
}

.transparent-btn:hover {
    color: #fff;
}

.coloured-btn {
    background-color: var(--main-color);
    color: #fff;
    border: 1px solid transparent;
    font-weight: 500;
    padding: 12px 17px;
}

.coloured-btn:hover {
        background-color: transparent;
        background-image: none;
        color: var(--main-color);
        border: 1px solid;
}