.footer {
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    background-color: var(--main-color);
    box-shadow: 1px 1px 15px 5px #b1b0b0;
}

.footer__copyright {
    text-align: center;
}

.footer__bold-span {
    color: #fff;
    font-weight: 500;
}

.footer__privacy-policy-link {
    text-decoration: none;
}

.footer__privacy-policy-link:hover {
    color: var(--secondary-color);
}

.footer__copyright,
.footer__privacy-policy-link {
    color: var(--white-color);
}