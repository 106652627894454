.aboutUs-team__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.aboutUs-team__description-wrapper {
  display: flex;
  align-items: center;
}

.aboutUs-team__img {
  /* width: 400px;
  max-width: 100%; */
  height: 300px;
}

.aboutUs-team__description {
  max-width: 800px;
  padding: 30px;
  overflow: hidden;
}

/* .aboutUs-team__description--right {
  border-right: 1px solid var(--main-color);
  margin: 50px 10px 50px 0;
}

.aboutUs-team__description--left {
  border-left: 1px solid var(--main-color);
  margin: 50px 0 50px 10px;
} */

.aboutUs-team__title {
  text-align: right;
  padding-bottom: 20px;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
}

.aboutUs-team__title--right {
  text-align: right;
}

.aboutUs-team__title--left {
  text-align: left;
}

.aboutUs-team__text {
  font-size: 1.8rem;
  font-weight: 300;
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

.aboutUs-team__text--left {
  text-align: right;
  transform: translateX(-200%);
}

.aboutUs-team__text--right {
  text-align: left;
  transform: translateX(200%);
}

.aboutUs-team__text.active {
  transform: translateX(0%);
  transition-duration: 0.4s;
  transition-delay: 0.1s;
}

.aboutUs-team__subtitle--right {
  text-align: right;
}

.aboutUs-team__subtitle--left {
  text-align: left;
}

@media (max-width: 400px) {
  .aboutUs-team__text {
    font-size: 1.6rem;
  }

  .aboutUs-team__description {
    padding: 15px;
  }

  .aboutUs-team__container {
    padding: 50px 15px;
  }
}

@media (max-width: 1250px) {
  .aboutUs-team__description-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
