.hero__img-container {
  margin-top: 77px;
  height: calc(100vh - 77px);
  width: 100%;
  background-size: cover;
  background-position: center;
}

.hero__headline {
  margin-top: 50px;
  text-align: center;
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  overflow-x: unset;
}

@media (max-width: 650px) {
  .hero__img-container {
    margin-top: 97px;
  }
}
