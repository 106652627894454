*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
  --black-color: #000;
  --white-color: #dadada;
  --gray-color: #999;
  --dark-gray-color: #252525;
  --main-color: #0f2f4e;
  --secondary-color: #96baca;
  --third-color: #dcedf2;
  --semi-black-color: #111;
  --container-max-width: 1366px;
  --section-padding: 4rem;
  --top-bottom-padding: 6rem;
  --color-gradient: linear-gradient(to right, var(--main-color), #33708a);
}

body {
  font-size: 1.6rem;
  font-family: 'Poppins', sans-serif;
  background-color: var(--third-color);
}