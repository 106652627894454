.aboutUs-company___container {
  padding-top: 50px;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.aboutUs-company___content {
  max-width: 800px;
  margin: 50px auto;
}

.aboutUs-company__headline {
  font-size: 4.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--main-color);
  text-align: center;
  overflow-x: unset;
}

.aboutUs-company__text {
  text-align: center;
  margin: 20px 0;
}
